





import { defineComponent, computed, PropType } from '@nuxtjs/composition-api';
import { ButtonVariant } from '../atoms/KEButton.vue';

const BUTTON_VARIANTS_MAP = {
  'orange-white': 'primary',
  'orange-black': 'primary-black',
  'white-black': 'secondary'
};

export default defineComponent({
  props: {
    element: {
      type: Object,
      default: () => ({})
    },
    defaultVariant: {
      type: String as PropType<ButtonVariant>,
      default: 'primary'
    }
  },
  setup(props) {
    const buttonVariant = computed(() => {
      const elementVariant = props.element?.custom_fields?.find(
        (field) => field.key === 'button_color'
      )?.value_select;
      if (!elementVariant) return props.defaultVariant;
      return BUTTON_VARIANTS_MAP[elementVariant] ?? props.defaultVariant;
    });

    return {
      buttonVariant
    };
  }
});
